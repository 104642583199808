<div
  class="bottom-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  [ngClass]="{ 'no-padding': noPadding, 'no-shadow': noShadow }"
>
  <app-logo *ngIf="hasLogo" fxHide fxShow.gt-xs [logoType]="'horizontal'"></app-logo>

  <div class="title" *ngIf="title">
    <span>{{ title }}</span>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
