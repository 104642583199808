<div class="cart-prompt">

  <ng-container *ngIf="choicesList?.length > 0 || choicesMandatory">

    <ng-container *ngIf="choicesList?.length > 0; else emptyChoicesTemplate">
      <ng-container *ngIf="choicesList?.length <= 6 && choicesDisplayMode !== 'DropDown'; else dropDownChoicesTemplate">
        <!-- Selection List -->
        <mat-selection-list [multiple]="false"
                            class="cart-promp-choices-list"
                            (selectionChange)="onChoiceChanged($event.options[0]?.value)"
                            [disabled]="readonly">
          <div mat-subheader>{{ commentTitle | transloco }}</div>
          <div *ngIf="control?.touched && control?.hasError('choiceRequired')"
               @errorInOutAnimation
               class="cart-prompt-errors">
            <mat-error>
              {{ 'COMMON.ERRORS.FIELD_REQUIRED_FORMAT' | transloco : { field: (commentTitle | transloco) } }}
            </mat-error>
          </div>
          <mat-list-option *ngFor="let choiceItem of choicesList"
                           [value]="choiceItem.value"
                           [selected]="choiceItem.value === selectedChoice"
                           [disabled]="choiceItem.disabled === 'true'">
            {{choiceItem.value}}
          </mat-list-option>
        </mat-selection-list>
      </ng-container>
      <ng-template #dropDownChoicesTemplate>
        <mat-form-field class="cart-promp-choices-dropdownfield">
          <mat-label>{{ commentTitle | transloco }}</mat-label>
          <mat-select [formControl]="selectedChoiceInputControl">
            <mat-option *ngIf="choicesList?.length > 5">
              <mat-select-search [list]="choicesList"
                                 [searchProperties]="['value']"
                                 (filtered)="filteredChoices = $event"
                                 [searchPlaceholder]="(commentTitle || 'SEARCH.SEARCH_PLACEHOLDER') | transloco">
              </mat-select-search>
            </mat-option>
            <mat-option *ngFor="let choice of filteredChoices"
                        [value]="choice.value"
                        [disabled]="choice.disabled">
              {{choice.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="control?.touched && control?.hasError('choiceRequired')">
            {{ 'COMMON.ERRORS.FIELD_REQUIRED_FORMAT' | transloco : { field: (commentTitle | transloco) } }}
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ng-container>
    <ng-template #emptyChoicesTemplate>
      <mat-error>
        {{ 'COMMON.ERRORS.FIELD_REQUIRED_FORMAT' | transloco : { field: (commentTitle | transloco) } }}
      </mat-error>
      <mat-error>
        {{ 'CART.PROMPT_NO_CHOICE_AVAILABLE' | transloco }}
      </mat-error><!-- <div>Aucun élément disponible, veuillez choisir une autre date ou commander plus tard</div> -->
    </ng-template>
  </ng-container>
  <!-- comment, can be mandatory -->
  <mat-form-field class="cart-prompt-field">
    <mat-icon matPrefix
              color="primary">chat_bubble_outline</mat-icon>
    <input matInput
           type="text"
           [placeholder]="(( (choicesMandatory && 'CART.PROMPT_COMMENT') || commentTitle || 'CART.PROMPT_COMMENT' ) | transloco ) + (commentMandatory ? ' *': '')"
           [formControl]="userCommentInputControl" />
    <mat-error *ngIf="control?.touched && control?.hasError('commentRequired')">
      {{ 'COMMON.ERRORS.FIELD_REQUIRED_FORMAT' | transloco : { field: ((choicesMandatory && 'CART.PROMPT_COMMENT') || commentTitle || 'CART.PROMPT_COMMENT' ) | transloco } }}
    </mat-error>
  </mat-form-field>

</div>
