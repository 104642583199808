import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef, Injector, ViewChild, Injectable, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, FormGroup, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { extractTouchedChanges } from '@app/core/helpers/forms-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import _sortBy from 'lodash-es/sortBy';
import { ChoiceItem, ChoicesDisplayMode } from '@app/core/api-client/models';
import { merge } from 'rxjs';
import { DateHelper } from '@app/core/helpers/date-helper';

@UntilDestroy()
@Component({
  selector: 'app-cart-prompt',
  templateUrl: './cart-prompt.component.html',
  styleUrls: ['./cart-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // OnPush empêche l'affichage de l'erreur lors de la validation forcé depuis la FORM (voir )
  providers: [
    // component self validates
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CartPromptComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CartPromptComponent),
      multi: true,
    }
  ],
  animations: [
    trigger(
      'errorInOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px' }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto' })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)' }))
        ])
      ]
    )
  ]
})
export class CartPromptComponent implements OnInit, OnChanges, Validator, ControlValueAccessor, AfterViewInit {

  @Input() choicesMandatory: boolean = false;
  @Input() choices?: string[] | null;
  @Input() choiceItems?: ChoiceItem[] | null;
  @Input() allowedChoices?: string[] | null;
  @Input() comment: string | null = null;
  @Output() commentChange = new EventEmitter<string>();
  @Input() commentTitle: string;
  @Input() commentMandatory: boolean;
  @Input() choicesDisplayMode: ChoicesDisplayMode = ChoicesDisplayMode.Auto;
  @Input() readonly: boolean = false;

  private _choicesAndChoicesItems: string[] = [];
  choicesList: Record<string, string>[] = [];
  filteredChoices: Record<string, string>[] = [];

  selectedChoiceInputControl: UntypedFormControl = new UntypedFormControl(null);
  private _selectedChoice: string | null = null;
  public get selectedChoice(): string | null {
    return this._selectedChoice;
  }
  public set selectedChoice(v: string | null) {
    this._selectedChoice = v;
    if (this.selectedChoiceInputControl.value != v) {
      this.selectedChoiceInputControl.setValue(v);
    }
  }

  control: AbstractControl;

  // TODO voir si c'est possible d'imbriquer le userCommentInput FormControl dans le FormControl du CartPromptComponent pour propager l'état disabled / invalide
  userCommentInputControl: UntypedFormControl = new UntypedFormControl(null, { updateOn: 'blur' });
  public get userComment() { return (this.userCommentInputControl.value as string)?.trim(); };
  public set userComment(v: string | null) {
    if (this.userCommentInputControl.value != v) {
      this.userCommentInputControl.setValue(v);
    }
  };

  private _controlValueAccessorOnChange: (_: any) => void = null;
  private _controlValueAccessorOnTouched: (_: any) => void = null;
  private _validatorChange: (_: any) => void = null;

  constructor(
    private _injector: Injector,
    private _cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    merge(
      this.userCommentInputControl.valueChanges,
      this.selectedChoiceInputControl.valueChanges
        .pipe(
          tap(selectedValue => this.selectedChoice = selectedValue)
        )
    )
      .pipe(
        untilDestroyed(this),
        tap(_ => this.notifyCommentChangedByUser())
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['choices'] || changes['choiceItems'] || changes['allowedChoices']) {
      //use a Set to distinct choices and merge choices and choiceItems
      this._choicesAndChoicesItems = Array.from(new Set([...(this.choices || []), ...(this.choiceItems?.map(c => c.name) || [])]));

      this.choicesList = this._choicesAndChoicesItems
        //exclude choiceItems not orderable
        .filter(c => {
          const ci = this.choiceItems?.find(ci => ci.name === c);
          return !ci || !ci.isConstrainOnOrderingDates || ci.allowedOrderingDates.some(dr => DateHelper.isBetween(new Date(), new Date(dr.startDate), new Date(dr.endDate)));
        })
        //map to record
        .map(c => ({ 'value': c, 'disabled': (this.allowedChoices?.includes(c) === false || false).toString() })) || [];

      //filteredChoices when using mat-select-search
      this.filteredChoices = this.choicesList.slice();
    }

    if (changes['comment']) {
      const { choice, comment } = this.parseComment(this.comment);
      this.selectedChoice = choice;
      this.userComment = comment;
    }

    if (changes['comment'] || changes['commentMandatory'] || changes['choices'] || changes['choiceItems'] || changes['allowedChoices']) {
      this._validatorChange?.call(this);

      //reset selected choice if not in allowed choices
      if (this.selectedChoice && !this.allowedChoices?.includes(this.selectedChoice)) {
        this.selectedChoice = null;
        this.notifyCommentChangedByUser(); //propagate changes
      }
    }

    if (changes['readonly']) {
      if (this.readonly) {
        this.userCommentInputControl.disable();
        this.selectedChoiceInputControl.disable();
      }
      else {
        this.userCommentInputControl.enable();
        this.selectedChoiceInputControl.enable();
      }
    }

  }

  onChoiceChanged(choice: string) {
    this.selectedChoice = choice;
    this.notifyCommentChangedByUser();
  }

  private parseComment(inputComment: string): { choice: string | null, comment: string } {
    inputComment = inputComment?.trim() || "";
    // try to extract a choice from the start of the input comment
    // -- order choices by length desc to handle choices with the same starting characters but different end
    const choices = _sortBy(this._choicesAndChoicesItems || [], (c) => c.length).reverse();
    const parsedChoice = choices.find(c => inputComment.startsWith(`[${c}]`)); //enclosed by brackets
    const parsedComment = inputComment.substring((parsedChoice?.length + 2) || 0).trim(); // length+2 because of brackets

    return { choice: parsedChoice, comment: parsedComment };
  }

  private buildCommentWithSelectedChoice(): string {
    let comment = '';
    if (this.selectedChoice) {
      comment = `[${this.selectedChoice}]`; // add choice enclosed by brackets
    }
    if (this.userComment) {
      comment = comment + (comment.length > 0 ? '  ' : '') + this.userComment; // add userComment separated by a space if needed
    }
    return comment.trim();
  }

  private notifyCommentChangedByUser() {
    this.comment = this.buildCommentWithSelectedChoice();
    this._controlValueAccessorOnChange?.call(this, this.comment);
    this._controlValueAccessorOnTouched?.call(this);
    this.commentChange.next(this.comment);
  }

  validate(control: AbstractControl): ValidationErrors {
    let errors = {};

    // if comment is Mandatory, at leat a comment should be entered
    const requiresComment = this.commentMandatory && (this.userComment?.trim() || "").length === 0;
    if (requiresComment) {
      errors = Object.assign(errors, { commentRequired: true });
      //notify control for error
      this.userCommentInputControl?.setErrors({ errors });
    }

    // if choices are available, at leat one choice should be selected
    const requiresChoice = (this.choicesMandatory && this.selectedChoice == null);
    if (requiresChoice) {
      errors = Object.assign(errors, { choiceRequired: true });
      //notify control for error
      this.selectedChoiceInputControl?.setErrors({ errors });
    }

    return errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._validatorChange = fn;
  }

  writeValue(obj: any): void {
    // set only if value changed (comment'setter do parse string)
    const newValue = obj?.toString();
    if (this.comment !== newValue) {
      this.comment = newValue;
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this._controlValueAccessorOnChange = fn;
  }
  registerOnTouched(fn: (_: any) => void): void {
    this._controlValueAccessorOnTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  ngAfterViewInit(): void {
    // resolve formControl for this instance, using injection AfterViewInit.
    // see: https://stackoverflow.com/a/51126965

    this.control = this._injector.get(NgControl, null)?.control;
    if (this.control) {
      const userCommentControl = this.userCommentInputControl;
      const selectedChoiceControl = this.selectedChoiceInputControl;
      // update component state if FormControl is touched (from Form submition)
      // this.control.statusChanges
      extractTouchedChanges(this.control)
        .pipe(
          untilDestroyed(this),
          tap(() => {
            this.validate(this.control);
            userCommentControl.markAsTouched();
            selectedChoiceControl.markAsTouched();
            this._cd.markForCheck();
          })
        )
        .subscribe();

    } else {
      // Component is missing form control binding
    }
  }


}
