import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-bottom-container',
  templateUrl: './bottom-container.component.html',
  styleUrls: ['./bottom-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomContainerComponent implements OnInit {
  @Input() title: string;
  @Input() noPadding: boolean;
  @Input() hasLogo: boolean;
  @Input() noShadow: boolean;


  constructor() { }

  ngOnInit(): void {
  }

}
